import { __awaiter } from "tslib";
import camelCase from "./utils/camelCase";
import AOS from 'aos';
import $ from 'jquery';
/**
 * PremierBlocks class
 * The class is responsible for importing the block files dynamically
 */
class PremierBlocks {
    constructor(className) {
        this.className = "premier-block";
        this.listOfBlockNames = [];
        this.className = className;
        this.nodeList = document.querySelectorAll("." + this.className);
        this.init();
    }
    checkIfStringExistsInArray(string, array) {
        return array.indexOf(string) > -1;
    }
    getBlockNames() {
        const blockNames = [];
        this.nodeList.forEach((node) => {
            // Get the block name from the data-block attribute
            let blockName = node.getAttribute("data-block") || null;
            // If the block name is null console.log a message
            if (blockName === null) {
                console.warn("Block name for is missing from the data-block attribute for the following node: ");
                console.warn(node);
            }
            // if the block name is not null push it to the array
            if (blockName !== null) {
                blockName = camelCase(blockName);
            }
            if (blockName !== null && !this.checkIfStringExistsInArray(blockName, blockNames)) {
                blockNames.push(blockName);
            }
        });
        return blockNames;
    }
    /**
     *  Import the block files dynamically using webpack
     *
     * @returns {void}
     */
    importBlockFiles() {
        if (this.listOfBlockNames.length === 0)
            return;
        this.listOfBlockNames.forEach((name) => __awaiter(this, void 0, void 0, function* () {
            try {
                const { Block } = yield import(
                /* webpackChunkName: "[request]" */
                `./blocks/${name}`);
                new Block(name).init();
            }
            catch (error) {
                console.error(error);
                console.warn(`Add the js file for the ${name} block. \n Create the missing file inside the blocks folder: premier/assets/js/blocks/${name}.js`);
            }
        }));
    }
    /**
     * Init the class
     *
     * @returns {void}
     */
    init() {
        console.group("🚀 Premier is running!");
        // Selecciona el div
        const myDiv = document.getElementById('masthead');
        // Agrega el evento de desplazamiento
        window.addEventListener('scroll', function () {
            // Verifica la posición de desplazamiento
            if (window.scrollY > 300) { // Cambia '300' por la cantidad de píxeles deseada
                // Agrega la clase si se ha desplazado más de 300 píxeles
                myDiv.classList.add('scrolled');
            }
            else {
                // Quita la clase si se ha desplazado menos de 300 píxeles
                myDiv.classList.remove('scrolled');
            }
        });
        // *******
        // AOS stuff
        // *******
        // init AOS
        var aosOffset = 150, aosDuration = 400;
        AOS.init({
            duration: aosDuration,
            easing: 'ease-out-cubic',
            startEvent: 'load',
            offset: aosOffset,
        });
        // If there are not nodes console.log a message
        if (this.nodeList.length === 0) {
            console.info("No nodes found with classname: " + this.className);
            return;
        }
        if (this.nodeList.length > 0) {
            const msg = this.nodeList.length > 1 ? "blocks" : "block";
            console.info("We found " + this.nodeList.length + " " + msg);
            this.listOfBlockNames = this.getBlockNames();
            console.info("List of block names: ");
            console.info(this.listOfBlockNames);
            this.importBlockFiles();
        }
        console.groupEnd();
    }
}
document.addEventListener("DOMContentLoaded", () => {
    new PremierBlocks("premier-block");
});
$(document).ready(function () {
    $('#mobileActive').on('click', function (e) {
        e.preventDefault();
        $('#mobile-menu').slideDown();
    });
    $('#mobile-close').on('click', function (e) {
        e.preventDefault();
        $('#mobile-menu').slideUp();
    });
    $('.menu-item-has-children > a').on('click', function (e) {
        $(this).toggleClass('active');
        e.preventDefault();
        $(this).find('+ .sub-menu').fadeToggle();
    });
    $('.location-selector a').on('click', function (e) {
        e.preventDefault();
        var target = $(this).data('target');
        $('.location-selector a').removeClass('active');
        $(this).addClass('active');
        $('.location-wrap').removeClass('active');
        $(target).addClass('active');
    });
    $('.data h3').on('click', function (e) {
        e.preventDefault();
        $(this).toggleClass('active');
        $(this).find('+ .info').slideToggle();
    });
    $('.accordion-content h3').on('click', function (e) {
        e.preventDefault();
        $(this).toggleClass('active');
        $(this).find('+ p, + ol, + p + ol, + ol +p').slideToggle();
    });
});
